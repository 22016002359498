<template>
  <v-container>
    <!-- INDICATORS COMPONENTS -->
    <v-row justify="space-between" align="center">
      <v-col cols="12" lg="8" order="2" order-lg="1">
        <v-row justify="space-around">
          <v-col cols="12" sm="6" md="4" lg="3"
                v-for="item in kpiIndicatorsData" v-bind:key="item.label">
            <KpiIndicator
                v-bind:item="item"
                v-bind:loaded="kpiIndicatorsDataLoaded"
                v-bind:currency="creditorCurrency">
            </KpiIndicator>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4" order="1" order-lg="2">
        <v-row justify="center">
          <v-switch
            class="align-self-center"
            v-model="selectChildrenCreditorAccounts"
            inset
            :disabled="selectedCreditorHasNoChildren"
            :label="$t('INCLUDE_SUB_CREDITORS')">
          </v-switch>
        </v-row>
      </v-col>
    </v-row>
    <!-- CHART COMPONENT -->
    <v-row class="mt-1" justify="center">
      <v-col cols="12" lg="8">
        <v-card elevation="6">
          <v-card-title class="headline justify-center">
            {{ $t('PENDING_INVOICES_GRAPHIC_TITLE', { currency: creditorCurrencySymbol }) }}
          </v-card-title>
          <v-card-text class="border-top pt-1">
            <v-row justify="center">
              <v-radio-group v-model="kpiChartSelectedOption"
                             :disabled="!kpiChartDataLoaded"
                             row>
                <template v-slot:label>
                  <div>{{ $t('DISPLAY_BY') }}</div>
                </template>
                <v-radio v-for="option in kpiChartOptions"
                         v-bind:key="option"
                         :label="$t(option)"
                         :value="option">
                </v-radio>
              </v-radio-group>
            </v-row>
          </v-card-text>
          <KpiChart class="pr-6"
                            v-if="kpiChartDataLoaded"
                            v-bind:kpi-data="kpiChartData"
                            v-bind:scale="kpiChartSelectedOption"
                            v-bind:currency-symbol="creditorCurrencySymbol">
          </KpiChart>
        </v-card>
      </v-col>
      <!-- PIE CHART COMPONENT -->
      <v-col cols="12" lg="4">
        <v-card elevation="6">
          <v-card-title class="headline justify-center">
            {{ $t('SUM_OF_BALANCE', { currency: creditorCurrencySymbol }) }}
          </v-card-title>
          <KpiPieChart class="px-xl-12 px-lg-0 px-12"
                       v-if="kpiPieChartDataLoaded"
                       v-bind:kpi-data="kpiPieChartData"
                       v-bind:currency-symbol="creditorCurrencySymbol">
          </KpiPieChart>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import kpiService from '@/services/kpiService';
import { mapState } from 'vuex';
import numberMixin from '@/mixins/numberMixin';
import merge from 'lodash/merge';
import KpiChart from '@/components/common/kpi/KpiChart';
import KpiIndicator from '@/components/common/kpi/KpiIndicator';
import KpiPieChart from '@/components/common/kpi/KpiPieChart';

export default {
  name: 'CreditorDashboard',
  mixins: [numberMixin],
  components: { KpiPieChart, KpiIndicator, KpiChart },
  data: () => ({
    currentCreditorId: null,
    kpiChartDataLoaded: false,
    kpiIndicatorsDataLoaded: false,
    kpiPieChartDataLoaded: false,
    kpiChartData: {},
    kpiPieChartData: {},
    kpiIndicatorsData: {
      invoicesIssued: { label: 'INVOICES_ISSUED', class: 'blue--text' },
      invoicesToPay: { label: 'INVOICES_TO_PAY', class: 'blue--text' },
      invoicesToValidate: { label: 'INVOICES_TO_VALIDATE', class: 'blue--text' },
      lateInvoices: { label: 'LATE_INVOICES', class: 'red--text' }
    },
    kpiChartSelectedOption: 'MONTHS',
    kpiChartOptions: ['MONTHS', 'WEEKS'],
    selectChildrenCreditorAccounts: true,
    creditorIdList: []
  }),
  computed: {
    ...mapState(['selectedCreditorAccount']),
    creditorValidationEnabled () {
      return this.selectedCreditorAccount?.creditorSettings?.VALIDATION_ENABLED?.value;
    },
    creditorCurrency () {
      return this.selectedCreditorAccount?.creditorSettings?.CURRENCY?.value;
    },
    creditorCurrencySymbol () {
      return this.getCurrencySymbol(this.creditorCurrency);
    },
    selectedCreditorHasNoChildren () {
      return !this.selectedCreditorAccount.children;
    }
  },
  methods: {
    async loadKpiChartData () {
      this.kpiChartDataLoaded = false;
      try {
        const { data } = await kpiService.getKpiChartData(this.creditorIdList, null);
        this.kpiChartData = data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.kpiChartDataLoaded = true;
      }
    },
    async loadKpiIndicatorsData () {
      this.kpiIndicatorsDataLoaded = false;
      try {
        const { data } = await kpiService.getCreditorKpiAbstract(this.creditorIdList, null);
        this.kpiIndicatorsData = merge(this.kpiIndicatorsData, data);
        if (!this.creditorValidationEnabled) {
          delete this.kpiIndicatorsData.invoicesToValidate;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.kpiIndicatorsDataLoaded = true;
      }
    },
    async loadKpiPieChartData () {
      this.kpiPieChartDataLoaded = false;
      try {
        const { data } = await kpiService.getCreditorKpiPieChartData(this.creditorIdList, null);
        this.kpiPieChartData = data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.kpiPieChartDataLoaded = true;
      }
    },
    getAllData () {
      this.loadKpiChartData();
      this.loadKpiIndicatorsData();
      this.loadKpiPieChartData();
    },
    computeCreditorIdList () {
      if (this.selectedCreditorHasNoChildren || !this.selectChildrenCreditorAccounts) {
        this.creditorIdList = [this.selectedCreditorAccount.id];
      } else {
        const childrenCreditorIdList = this.getCreditorChildrenIdsRecursively(this.selectedCreditorAccount, []);
        this.creditorIdList = [].concat(this.selectedCreditorAccount.id, childrenCreditorIdList);
      }
    },
    getCreditorChildrenIdsRecursively (creditorAccount, childrenCreditorIdList) {
      for (let i = 0; i < creditorAccount.children.length; i++) {
        const childCreditorAccount = creditorAccount.children[i];
        childrenCreditorIdList.push(childCreditorAccount.id);
        childCreditorAccount.children && this.getCreditorChildrenIdsRecursively(childCreditorAccount, childrenCreditorIdList);
      }
      return childrenCreditorIdList;
    }
  },
  created () {
    this.computeCreditorIdList();
  },
  watch: {
    selectedCreditorAccount () {
      this.computeCreditorIdList();
    },
    creditorIdList () {
      this.getAllData();
    },
    selectChildrenCreditorAccounts (value) {
      if (value) {
        const childrenCreditorIdList = this.getCreditorChildrenIdsRecursively(this.selectedCreditorAccount, []);
        this.creditorIdList = this.creditorIdList.concat(childrenCreditorIdList);
      } else {
        this.creditorIdList = [this.selectedCreditorAccount.id];
      }
    }
  }
};
</script>

<style scoped>
.width-5-vw {
  width: 5vw;
}
</style>