<template>
  <v-dialog v-model="addOrEditUserDialog" max-width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-if="action === 'edit'"
            v-on="{ ...tooltip, ...dialog}"
            v-bind="attrs"
            class="ma-2"
            color="primary"
            small
            ref="openingButton"
          >
            <v-icon
              dark
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('LABEL_UPDATE_USER' ) }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-4 text-h6 force-break-word">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="pa-4"
          @input="validateForm()"
        >
          <v-select
            v-model="creditorUser.gender"
            :items="titleItems"
            :rules="[rules.requiredFieldRules]"
            :label="$t('LABEL_TITLE')"
            required
          ></v-select>

          <v-text-field
            v-model="creditorUser.lastName"
            :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
            :label="$t('LABEL_LASTNAME')"
            counter="20"
            required
          ></v-text-field>

          <v-text-field
            v-model="creditorUser.firstName"
            :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
            :label="$t('LABEL_FIRSTNAME')"
            counter="20"
            required
          ></v-text-field>

          <PhoneNumberInput :phone-number.sync="phoneNumber"
                            :phone-mask-key.sync="creditorUser.phoneMaskKey"
                            :creditorId="this.selectedCreditorAccount.id">
          </PhoneNumberInput>

          <v-text-field
            v-model="creditorUser.email"
            :rules="[rules.emailRules]"
            :label="$t('LABEL_EMAIL')"
            :disabled="action === 'edit'"
            required
          ></v-text-field>

          <v-radio-group
           v-model="creditorUser.mainRole"
           required
           :rules="[rules.requiredFieldRules]"
          >
            <template v-slot:label>
              <div>{{ $t('LABEL_ROLE') }}</div>
            </template>
            <v-radio
              v-for="role in creditorRoles.filter(role => !role.shouldBeHidden)"
              :key="role.value"
              :label="role.text"
              :value="role.value"
            >
            </v-radio>
          </v-radio-group>

          <v-row
            justify="space-around">
            <v-btn
              color="error"
              @click="closeDialog"
              class="my-4"
            >
              {{ $t('ACTION_CANCEL') }}
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="validateLoading"
              color="primary"
              @click="validate"
              class="my-4"
            >
              {{ $t('ACTION_VALIDATE') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userMixin from '@/mixins/userMixin';
import userService from '@/services/userService';
import userMapper from '@/mappers/userMapper';
import maskMixin from '@/mixins/maskMixin';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import ruleMixin from '@/mixins/ruleMixin';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';
import { mapState } from 'vuex';

export default {
  mixins: [userMixin, maskMixin, ruleMixin],
  components: { PhoneNumberInput },
  props: {
    action: {
      type: String,
      required: true
    },
    userProp: {
      type: Object,
      default: () => ({})
    },
    creditorAccountsGroups: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      addOrEditUserDialog: false,
      valid: undefined,
      validateLoading: false,
      creditorUser: {},
      phoneNumber: undefined,
      phoneMaskKey: undefined
    };
  },
  computed: {
    dialogTitle () {
      let title = '';
      if (this.action === 'add') {
        title = this.$t('TITLE_CREATE_USER_ACCOUNT');
      } else if (this.action === 'edit') {
        title = this.$t('TITLE_EDIT_USER') + ' ' + this.creditorUser.email;
      }
      return title;
    },
    ...mapState(['selectedCreditorAccount'])
  },
  watch: {
    userProp: {
      deep: true,
      immediate: true,
      async handler () {
        this.creditorUser = cloneDeep(this.userProp);
        if (!isEmpty(this.creditorUser)) {
          this.phoneNumber = this.creditorUser.phoneNumber;
          const creditorUserGroups = await userService.getCreditorAccountsGroup(this.creditorUser.id);
          this.creditorUser.groups = creditorUserGroups.data;
        }
        if (!this.creditorUser.roles) {
          this.creditorUser.roles = [];
        }
      }
    }
  },
  methods: {
    // Allow to display all form errors without clicking on every input
    validateForm () {
      if (this.action === 'edit') {
        this.$refs.form?.validate();
      }
    },
    async openDialog () {
      this.addOrEditUserDialog = true;
    },
    closeDialog () {
      this.addOrEditUserDialog = false;
      if (this.action === 'add') {
        this.$refs.form.reset();
      }
    },
    async validate () {
      this.validateLoading = true;
      this.creditorUser.phoneNumber = this.replaceWithoutBlank(this.phoneNumber);
      this.creditorUser.groups = this.userProp.groups;
      this.creditorUser.userMainRole = this.creditorUser.mainRole;
      if (this.action === 'add') {
        try {
          this.creditorUser.groups = this.creditorAccountsGroups.map(creditorAccountGroup => creditorAccountGroup.path);
          await userService.createCreditorUser(userMapper.mapToKeycloakUser(this.creditorUser, this.creditorUser.mainRole));
          this.displaySuccessSnackbar(this.$t('CREDITOR_SUCCESS_MESSAGE_NEW_USER'));
          this.closeDialog();
        } catch (err) {
          this.handleError(err);
        }
      } else if (this.action === 'edit') {
        try {
          this.creditorUser.profile = this.creditorUser.userMainRole;
          await userService.updateCreditorUser(this.creditorUser);
          this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_EDIT_USER'));
          this.closeDialog();
        } catch (err) {
          this.handleError(err);
        }
      }
      this.validateLoading = false;
      this.$emit('refresh-table');
    }
  }
};
</script>