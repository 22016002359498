<template>
  <div class="fill-height">
    <v-row
      align="center"
      class="py-1 pt-3"
      v-if="!hideMainNavigation">
      <AppCreditorMenu/>
    </v-row>
    <v-row class="fill-height">
      <v-col class="mt-5" cols="12">
        <div
          class="container"
          v-if="hideMainNavigation && !hideReturnButton">
          <v-btn
            color="primary"
            class="back-home-button"
            :to="{ name: 'CreditorHome' }">
            <v-icon left>
              mdi-arrow-left-bold
            </v-icon>
            {{ $t("BACK_TO_HOME") }}
          </v-btn>
        </div>
        <router-view/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppCreditorMenu from '@/components/common/menus/AppCreditorMenu';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    AppCreditorMenu
  },
  computed: {
    ...mapState(['debtorAccounts']),
    isDebtorAccountsListEmpty () {
      return !this.debtorAccounts?.length;
    },
    showDebtorSelector () {
      return this.debtorAccounts?.length > 1;
    },
    hideMainNavigation () {
      return this.$route.matched.some(route => route.meta.hideMainNavigation);
    },
    hideReturnButton () {
      return this.$route.matched.some(route => route.meta.hideReturnButton);
    }
  }
};
</script>

<style scoped>
.right-border {
  border-right: 1px lightgray solid;
}

.back-home-button::before {
  opacity: 0;
}
</style>