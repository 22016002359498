<template>
  <v-autocomplete
    v-model="selectedDebtorAccounts"
    :items="sortedDebtorAccounts"
    ref="debtorAccountSelector"
    item-text="name"
    item-value="id"
    :filter="filterDebtors"
    :search-input.sync="search"
    :placeholder="$t('START_TEXT')"
    dense
    outlined
    :label="label"
    return-object
    hide-details
    hide-no-data
    class="py-3"
    persistent-hint
    persistent-placeholder
    open-on-clear
    multiple
    @focus="hasFocus"
    @blur="loseFocus"
  >
    <template v-slot:prepend-item>
      <v-container>
        <v-row class="d-flex justify-space-around mb-2">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                  @click="selectAll"
                  class="mx-1"
                  small
                  v-on="on"
                  :disabled="disableAllButton"
              >
              {{ $t('ALL') }}
              </v-btn>
            </template>
            {{ $t('ALL_TOOLTIP') }}
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                  @click="removeAll"
                  class="mx-1"
                  small
                  v-on="on"
                  :disabled="disableNoneButton"
              >
                {{ $t('NONE') }}
              </v-btn>
            </template>
            {{ $t('NONE_TOOLTIP') }}
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="validateDebtorSelection"
                class="mx-1"
                small
                color="primary"
                v-on="on"
              >
                {{ $t('ACTION_VALIDATE') }}
              </v-btn>
            </template>
            {{ $t('VALIDATE_TOOLTIP') }}
          </v-tooltip>
        </v-row>
        <v-row class="info-counter">
            {{ $t('ACCOUNT_SEL') }}{{selectedDebtorCounter}}
        </v-row>
      </v-container>
      <v-divider class="mt-1 mb-1"></v-divider>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.accountId"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';
import userService from '@/services/userService';

export default {
  data () {
    return {
      selectedDebtorAccounts: [],
      debtorAccounts: [],
      label: this.$t('ACCOUNT_SEARCH'),
      queryTerm: '',
      oldQueryTerm: ''
    };
  },
  computed: {
    ...mapState(['selectedDebtorAccountsForCreditor', 'selectedCreditorAccount']),
    sortedDebtorAccounts () {
      return this.sortDebtorAccountList(this.debtorAccounts);
    },
    disableAllButton () {
      const selectedDebtorIds = this.selectedDebtorAccounts.map(debtorAccount => debtorAccount.id);
      return this.$refs.debtorAccountSelector.filteredItems.every(debtorAccount => { return selectedDebtorIds.includes(debtorAccount.id); });
    },
    disableNoneButton () {
      const selectedDebtorIds = this.selectedDebtorAccounts.map(debtorAccount => debtorAccount.id);
      return this.$refs.debtorAccountSelector.filteredItems.every(debtorAccount => { return !selectedDebtorIds.includes(debtorAccount.id); });
    },
    initialDebtorCounter () {
      this.$store.dispatch('setDebtorAccounts', { debtorAccounts: this.debtorAccounts });
      return this.debtorAccounts.length;
    },
    selectedDebtorCounter () {
      return this.selectedDebtorAccounts.length;
    },
    search: {
      get () {
        return this.queryTerm;
      },
      set (searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          if (this.queryTerm !== null) {
            if (this.queryTerm.length >= 3) {
              setTimeout(() => {
                this.loadDebtorAccounts();
              }, 1000);
            }
          }
        }
      }
    }
  },
  methods: {
    hasFocus () {
      this.label = this.$t('ACCOUNT_SEARCH') + this.initialDebtorCounter;
    },
    loseFocus () {
      this.label = this.$t('ACCOUNT_SEL') + this.selectedDebtorCounter;
    },
    filterDebtors (item, queryText) {
      return (item.name.toLowerCase().includes(queryText.toLowerCase()) || item.accountId.includes(queryText));
    },
    async loadDebtorAccounts () {
      if (this.oldQueryTerm !== this.queryTerm) {
        this.oldQueryTerm = this.queryTerm;
        const { data } = await userService.getDebtorsByCreditorAndDebtorData(this.$store.state.selectedCreditorAccount.id, this.queryTerm);
        this.debtorAccounts = data;
        this.label = this.$t('ACCOUNT_SEARCH') + this.initialDebtorCounter;
        this.selectedDebtorAccounts = [];
      }
    },
    sortDebtorAccountList (array) {
      return array.sort((a, b) => a.name.localeCompare(b.name));
    },
    selectAll () {
      this.label = this.$t('ACCOUNT_SEARCH') + this.initialDebtorCounter;
      const autocompleteRef = this.$refs.debtorAccountSelector;
      autocompleteRef.focus();
      this.selectedDebtorAccounts = [...new Set(this.$refs.debtorAccountSelector.filteredItems.concat(this.$refs.debtorAccountSelector.selectedItems))];
    },
    removeAll () {
      this.label = this.$t('ACCOUNT_SEARCH') + this.initialDebtorCounter;
      const autocompleteRef = this.$refs.debtorAccountSelector;
      autocompleteRef.focus();
      const accountsToRemove = this.$refs.debtorAccountSelector.filteredItems;
      this.selectedDebtorAccounts = this.$refs.debtorAccountSelector.selectedItems.filter(element => accountsToRemove.indexOf(element) < 0);
    },
    validateDebtorSelection () {
      if (this.$refs.debtorAccountSelector) {
        this.$refs.debtorAccountSelector.blur();
      }
      this.$store.dispatch('setSelectedDebtorAccountsForCreditor', { debtorAccounts: this.selectedDebtorAccounts });
    }
  },
  beforeMount () {
    if (this.$store.state.debtorAccounts) {
      this.debtorAccounts = this.$store.state.debtorAccounts;
    }
    if (this.$store.state.selectedDebtorAccountsForCreditor) {
      this.selectedDebtorAccounts = this.$store.state.selectedDebtorAccountsForCreditor;
      this.label = this.$t('ACCOUNT_SEL') + this.selectedDebtorCounter;
    }
  }
};
</script>

<style scoped>
  .v-select.v-input--dense /deep/ .v-select__selection--comma {
    display: none;
  }
  .info-counter {
    color: #2108BD;
    font-size: 0.8em;
    text-align: justify;
    font-style: italic;
    padding-top: 1em;
    padding-left: 1em;
  }
</style>
