<template>
  <v-card>
    <v-card-title>
      <ExportCsvButton
          :loadingData="loading"
          :headers="exportableHeaders"
          :invoices="invoices"
          :filename="$t('NAME_CSV_FILE_REJECTED')">
      </ExportCsvButton>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('SEARCH')"
          single-line
          hide-details
          clearable
          @input="getInvoices(debtorAccounts.map(debtorAccount => debtorAccount.id))"
      ></v-text-field>
    </v-card-title>
    <v-row>
      <DebtorAccountSelectorForCreditor
          :class="{'debtor-selector': true, 'debtor-selector-not-xs': !$vuetify.breakpoint.xs}"
          :initialSelectedDebtorAccounts="debtorAccounts"
      />
    </v-row>
    <v-data-table
        :headers="headers.filter(header => !header.hidden)"
        :items="invoices"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalInvoices"
        :items-per-page="20"
        :footer-props="footerProps"
        :sort-by.sync="defaultSortBy"
    >

      <template v-slot:item.debtorName="{ item }">
        <strong>{{ item.debtor.name }}</strong>
      </template>
      <template v-slot:item.refDoc="{ item }">
        <InvoiceNumberWithDownloadLink :invoice="item"></InvoiceNumberWithDownloadLink>
      </template>
      <template v-slot:item.issueDate="{ item }">
        <span>{{ item.issueDate | date }}</span>
      </template>
      <template v-slot:item.amountExclusiveOfTax="{ item }">
        <span>{{ item.amountExclusiveOfTax | currency(item.currency) }}</span>
      </template>
      <template v-slot:item.amountWithTax="{ item }">
        <span>{{ item.amountWithTax | currency(item.currency) }}</span>
      </template>
      <template v-slot:item.dueDate="{ item }">
        <span>{{ item.dueDate | date }}</span>
      </template>
      <template v-slot:item.outstandingAmount="{ item }">
        <BoldAndRelativeAmount
            :amount="item.outstandingAmount"
            :isInvoice="item.isInvoice"
            :currency="item.currency"
            v-if="item.currency && item.temporaryOutstandingAmount === item.outstandingAmount"
        />
        <ItalicTemporaryAmount
            :amount="item.temporaryOutstandingAmount"
            :isInvoice="item.isInvoice"
            :currency="item.currency"
            v-if="item.currency && item.temporaryOutstandingAmount !== item.outstandingAmount"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" :to="{
          name: 'CreditorInvoiceDetail',
          params: { id: item.id }}">
          {{ $t('BUTTON_DETAILS') }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';
import { mapState } from 'vuex';
import ExportCsvButton from '@/components/common/buttons/ExportCsvButton';
import BoldAndRelativeAmount from '@/components/common/BoldAndRelativeAmount';
import InvoiceNumberWithDownloadLink from '@/components/common/buttons/InvoiceNumberWithDownloadLink';
import DebtorAccountSelectorForCreditor from '@/components/creditor/DebtorAccountSelectorForCreditor';
import ItalicTemporaryAmount from '@/components/common/ItalicTemporaryAmount';
import debounce from 'lodash/debounce';

export default {
  name: 'InvoiceRejectedTableForCreditor',
  mixins: [dateMixin, numberMixin],
  components: {
    ExportCsvButton,
    BoldAndRelativeAmount,
    InvoiceNumberWithDownloadLink,
    DebtorAccountSelectorForCreditor,
    ItalicTemporaryAmount
  },
  props: {
    debtorAccounts: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentUsedOptions: {},
      currentUsedDebtorAccountId: null,
      currentUsedSearchValue: '',
      totalInvoices: 0,
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100]
      },
      defaultSortBy: 'issueDate',
      options: {},
      headers: [
        {
          text: this.$t('DEBTOR_ACCOUNT'),
          value: 'debtorName',
          exportable: true
        },
        {
          text: this.$t('ACCOUNT_ID'),
          value: 'billingAccount',
          exportable: true,
          hidden: true
        },
        {
          text: this.$t('LABEL_DOCUMENT_REFERENCE'),
          value: 'refDoc',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('STATUS'),
          value: 'status',
          exportable: true,
          hidden: true
        },
        {
          text: this.$t('INVOICE_ISSUE_DATE'),
          value: 'issueDate',
          sortable: false,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_EXCLUDING_TAXES'),
          value: 'amountExclusiveOfTax',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_INCLUDING_TAXES'),
          value: 'amountWithTax',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('INVOICE_DUE_DATE'),
          value: 'dueDate',
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_OUTSTANDING'),
          value: 'outstandingAmount',
          sortable: false,
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          value: 'actions',
          sortable: false,
          exportable: false
        }
      ],
      invoices: [],
      loading: true,
      search: ''
    };
  },
  computed: {
    exportableHeaders () {
      return this.headers.filter(header => !!header.exportable);
    },
    ...mapState(['selectedCreditorAccount'])
  },
  async beforeMount () {
    if (!this.selectedCreditorAccount?.creditorSettings.VALIDATION_ENABLED.value) {
      await this.$router.push({ name: 'Error' });
    }
  },
  methods: {
    getInvoices: debounce(async function (debtorIds) {
      if (!this.search) {
        this.search = '';
      }
      this.loading = true;
      try {
        const { data } = await invoiceService.getPaginatedRejectedInvoicesByDebtorIds(debtorIds, this.selectedCreditorAccount.id,
          this.search, this.options);
        this.totalInvoices = data.totalElements;
        this.invoices = invoiceMapper.mapAll(data.content);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }, 500)
  },
  watch: {
    options: {
      handler () {
        return this.getInvoices(this.debtorAccounts.map(debtorAccount => debtorAccount.id));
      },
      deep: true
    },
    debtorAccounts () {
      return this.getInvoices(this.debtorAccounts.map(debtorAccount => debtorAccount.id));
    }
  }
};
</script>

<style scoped>
.list-item-padding-0 {
  padding: 0px;
}
.debtor-selector {
  padding-right: 15px;
  padding-left: 15px;
}

.debtor-selector-not-xs {
  max-width: 370px;
  min-width: 200px;
  padding-left: 40px;
}
</style>