<template>
  <v-dialog v-if="!loading"
            v-model="validationDialog"
            width="600"
            persistent>
    <v-card>
      <v-card-title class="pa-4 text-h6 force-break-word">
        {{ $t('CREDITOR_ACCOUNT_SETTINGS') }}
      </v-card-title>
      <v-list dense class="pl-12">
        <v-list-item-group>
          <v-list-item>
            <v-list-item-icon>
              <v-icon dense>mdi-chevron-right-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('CURRENCY')}}{{ creditorAccount.creditorSettings.CURRENCY.value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon dense>mdi-chevron-right-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('VALIDATION_ENABLED')}}{{ $t(toLabel(creditorAccount.creditorSettings.VALIDATION_ENABLED.value)) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon dense>mdi-chevron-right-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('CAPTURE_MODE')}}{{ $t(captureMode) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon dense>mdi-chevron-right-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('MULTIPLE_PAYMENT_ENABLED')}}{{ $t(toLabel(creditorAccount.creditorSettings.MULTIPLE_PAYMENT_ENABLED.value)) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider class="mx-4"></v-divider>
      <v-card-title class="pa-4 text-h6 force-break-word">
        {{ $t('PAYMENT_MEAN_LIST') }}
      </v-card-title>
      <v-expansion-panels v-if="paymentMeans.length > 0"
        accordion
        flat
        multiple>
        <v-expansion-panel
          v-for="item in paymentMeans"
          :key="item.id"
        >
          <v-expansion-panel-header class="font-weight-medium">
            {{ $t('PAYMENT_TYPE') }}{{ item.type }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item class="pl-12">
                <v-list-item-icon>
                  <v-icon dense>mdi-chevron-right-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('PAYMENT_PROVIDER') }}{{ item.paymentProvider }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="getPaymentMeanMerchantId(item)" class="pl-12">
                <v-list-item-icon>
                  <v-icon dense>mdi-chevron-right-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('MERCHANT_ID') }}{{ getPaymentMeanMerchantId(item) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.creditorIBAN" class="pl-12">
                <v-list-item-icon>
                  <v-icon dense>mdi-chevron-right-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('CREDITOR_IBAN') }}{{ item.creditorIBAN }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pl-12">
                <v-list-item-icon>
                  <v-icon dense>mdi-chevron-right-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('MIN_AMOUNT') }}{{ item.minAmount }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pl-12">
                <v-list-item-icon>
                  <v-icon dense>mdi-chevron-right-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('MAX_AMOUNT') }}{{ item.maxAmount }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.creditorId" class="pl-12">
                <v-list-item-icon>
                  <v-icon dense>mdi-chevron-right-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('CREDITOR_ID') }}{{ item.creditorId }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row v-else class="mx-0">
        <v-col cols="12" align="center">{{ $t('NO_PAYMENT_MEANS_CONFIGURED') }}</v-col>
      </v-row>
      <v-card-actions>
        <v-row
          justify="space-around" class="my-4">
          <v-btn
            color="primary"
            @click="close"
          >
            {{ $t('ACTION_CLOSE') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import paymentMeanService from '@/services/paymentMeanService';
export default {
  name: 'CreditorAccountDetailsDialog',
  data () {
    return {
      loading: true,
      paymentMeans: [],
      validationDialog: false
    };
  },
  props: {
    creditorAccount: {
      type: Object,
      required: true
    }
  },
  watch: {
    creditorAccount () {
      this.loadDataPaymentMeans();
    }
  },
  methods: {
    close () {
      this.validationDialog = false;
    },
    open () {
      this.validationDialog = true;
    },
    async loadDataPaymentMeans () {
      this.loading = true;
      try {
        const { data } = await paymentMeanService.getPaymentMeansByCreditorId(this.creditorAccount.id);
        this.paymentMeans = data.filter(paymentMean => paymentMean.type !== 'CREDIT_NOTE');
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    getPaymentMeanMerchantId (paymentMean) {
      return (paymentMean.subMerchantId ? `${paymentMean.subMerchantId} (${paymentMean.merchantId})` : paymentMean.merchantId) || paymentMean.acceptanceAccountId;
    },
    toLabel (boolean) {
      return boolean ? 'LABEL_YES' : 'LABEL_NO';
    }
  },
  computed: {
    captureMode () {
      return this.creditorAccount.twoStepPaymentEnabled ? 'CAPTURE_EXPLICIT' : 'CAPTURE_IMPLICIT';
    }
  }
};
</script>

<style scoped>
.mytable table tr {
    border-bottom: none;
    border-top: none;
    padding-left: 20px;
    border-spacing: 0 0.8rem;
}
</style>