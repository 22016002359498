<template>
    <v-dialog v-model="dialogValidConsent" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          :color="consentChipColor(samLastValidConsentExpirationDate)"
          :disabled="samLoading"
        >
          <v-icon
            class="pr-3"
            color="white"
          >
            mdi-cog
          </v-icon>
          <strong class="text-transform-none">{{ samLastValidConsentExpirationDate ? $options.filters.dateTime(samLastValidConsentExpirationDate) : $t('NO_VALID_CONSENTS') }}</strong>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>{{ $t('CONSENTS_MANAGEMENT') }}</h1>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text class="iframe-container">
          <iframe v-if="isValidUrl(samSession)" :src="samSession.redirectUrl" class="responsive-iframe" title="consents-management"></iframe>
          <v-alert
            v-else
            outlined
            type="error"
          >
            {{ $t('CONSENTS_MANAGEMENT_MODAL_ERROR_MESSAGE') }}
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer><v-btn @click="onCloseSamIframe">{{ $t('ACTION_CLOSE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';

export default {
  mixins: [dateMixin],
  props: {
    samSession: {
      type: Object,
      default: undefined
    },
    samLastValidConsentExpirationDate: {
      type: String,
      default: undefined
    },
    samLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      dialogValidConsent: false,
      daysThresholdBeforeOrangeChip: 7
    };
  },
  methods: {
    consentChipColor (lastValidConsentExpirationDate) {
      const diffDate = this.daysSince(lastValidConsentExpirationDate);
      if (!lastValidConsentExpirationDate || diffDate > 0) {
        return 'error';
      } else if (diffDate > -this.daysThresholdBeforeOrangeChip + 1) {
        return 'warning';
      } else {
        return 'success';
      }
    },
    isValidUrl (session) {
      if (session?.redirectUrl) {
        return true;
      }
      return false;
    },
    onCloseSamIframe () {
      this.dialogValidConsent = false;
      this.$emit('refresh-table');
    }
  }
};
</script>

<style scoped>
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  h1 {
    font-size: 1.875rem;
  }

  .iframe-container {
    min-height: 70vh;
    min-width: 70vw;
    position: relative;
    margin: 0;
  }
</style>