<template>
  <v-dialog v-model="dialogDisable" max-width="600px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-on="{ ...tooltip, ...dialog}"
            v-bind="attrs"
            class="ma-2"
            color="red"
            small
            dark
          >
            <v-icon
              dark
              small
            >
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('LABEL_DELETE_USER' ) }}</span>
      </v-tooltip>
    </template>
    <v-card class="py-2">
      <v-card-title class="headline text-center">
        <span class="force-break-word">{{ $t('CONFIRMATION_DISABLE_USER') }}</span>
      </v-card-title>

      <v-row no-gutters justify="center">
        <v-list>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('COMPLETE_NAME_LABEL') }}</v-list-item-subtitle>
              <v-list-item-title>{{ completeName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('EMAIL_LABEL') }}</v-list-item-subtitle>
              <v-list-item-title>{{ userProp.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('ROLE_LABEL') }}</v-list-item-subtitle>
              <v-list-item-title>{{ computeCreditorUserRole(userProp.mainRole) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-row>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn
          color="error"
          dark
          @click="dialogDisable = false"
        >
          {{ $t('ACTION_CANCEL') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          @click="disableUser"
          :loading="disableLoading"
        >
          {{ $t('ACTION_VALIDATE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from '@/services/userService';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  props: {
    userProp: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      disableLoading: false,
      dialogDisable: false
    };
  },
  computed: {
    completeName () {
      return `${this.computeTitle(this.$props.userProp.gender)} ${this.$props.userProp.firstName} ${this.$props.userProp.lastName}`;
    }
  },
  methods: {
    async disableUser () {
      this.disableLoading = true;
      try {
        userService.disableCreditorUser(this.userProp.id);
        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_DISABLE_USER'));
        this.dialogDisable = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.$emit('refresh-table');
        this.disableLoading = false;
      }
    }
  }
};
</script>
