<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <em
          v-if="isInvoice"
          class="black--text"
          v-on="on"
      >
        {{ amount | currency(currency) }} {{ star }}
      </em>
      <em
          v-else
          class="green--text text--darken-4"
          v-on="on"
      >
        {{ -amount | currency(currency) }} {{ star }}
      </em>
    </template>
    {{ $t('WAITING_PAYMENT_VALIDATION') }}
  </v-tooltip>
</template>

<script>
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [
    numberMixin
  ],
  props: {
    amount: {
      type: Number,
      required: true
    },
    isInvoice: {
      type: Boolean,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      star: '*'
    };
  }
};
</script>
