<template>
  <v-card>
    <CreditorAccountDetailsDialog
          ref="creditorAccountDetailsDialog"
          :creditorAccount="creditorAccountForDialog">
    </CreditorAccountDetailsDialog>
    <CreditorAccountUpdateDialog
          ref="creditorAccountUpdateDialog"
          :nameAccount="accountNameForDialog"
          :idAccount="accountIdForDialog"
          @refresh-table="loadDataTableContent">
    </CreditorAccountUpdateDialog>
    <v-card-text>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="2" align="center">
          <div class="black--text font-weight-bold">{{ $t('LABEL_NAME') }}</div>
        </v-col>
        <v-col cols="2" align="center">
          <div class="black--text font-weight-bold">{{ $t('LABEL_ACCOUNT_ID') }}</div>
        </v-col>
        <v-col cols="1" align="center">
          <div class="black--text font-weight-bold">{{ $t('TYPE') }}</div>
        </v-col>
        <v-col cols="4" align="center">
          <div class="black--text font-weight-bold">{{ $t('LABEL_LAST_VALID_CONSENT_EXPIRATION_DATE') }}</div>
        </v-col>
        <v-col cols="2" align="center">
          <div class="black--text font-weight-bold">{{ $t('LABEL_ACTIONS') }}</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-treeview
        :items="creditorAccounts"
        item-key="accountId"
        :open.sync="openedByDefault"
      >
        <template v-slot:label="{ item }">
          <v-row>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <v-col align-self="center"><v-icon>{{ item.children ? 'mdi-file-document-multiple' : 'mdi-file-replace-outline' }}</v-icon></v-col>
            <v-col cols="2" align="center" align-self="center">{{item.name}}</v-col>
            <v-col cols="2" align="center" align-self="center">{{item.accountId}}</v-col>
            <v-col cols="1" align="center" align-self="center">{{item.type}} </v-col>
            <v-col cols="4" align="center">
              <CreditorSettingsAccountsSamIframe
                        v-if="item.pisActive"
                        :samSession="item.session"
                        :samLastValidConsentExpirationDate="item.lastValidConsentExpirationDate"
                        :samLoading="loading"
                        :disabled="disableSamIfNoConsentManagementRights"
                        @refresh-table="loadDataTableContent"/>
            </v-col>
            <v-col cols="2" align="center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    color="primary"
                    @click="openCreditorAccountDetailsDialog(item)"
                    small
                    class="mx-2">
                    <v-icon small>mdi-file-document-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('BUTTON_DETAILS') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    color="primary"
                    :disabled="disableIfNoAdminRights"
                    @click="openCreditorAccountUpdateDialog(item.name, item.id)"
                    small
                    class="mx-2">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('ACTION_UPDATE') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import environmentService from '@/services/environmentService';
import localeService from '@/services/localeService';
import userService from '@/services/userService';
import userMixin from '@/mixins/userMixin';
import CreditorSettingsAccountsSamIframe from '@/views/creditor/settings/CreditorSettingsAccountsSamIframe';
import CreditorAccountDetailsDialog from '@/components/creditor/CreditorAccountDetailsDialog';
import CreditorAccountUpdateDialog from '@/components/creditor/CreditorAccountUpdateDialog';

export default {
  components: {
    CreditorSettingsAccountsSamIframe,
    CreditorAccountUpdateDialog,
    CreditorAccountDetailsDialog
  },
  mixins: [userMixin],
  data () {
    return {
      loading: true,
      creditorAccountForDialog: {},
      accountNameForDialog: '',
      accountIdForDialog: '',
      creditorAccounts: [],
      creditorAccountsWithChildren: [],
      listChildren: [],
      openedByDefault: []
    };
  },
  computed: {
    samLocale () {
      return localeService.currentLocale.split('-')[0];
    },
    disableIfNoAdminRights () {
      return !this.isCreditorAdmin();
    },
    disableSamIfNoConsentManagementRights () {
      return (!this.isCreditorAdmin() || !this.isConsentManager());
    }
  },
  methods: {
    async loadDataTableContent () {
      this.loading = true;
      try {
        const { data } = await userService.getRealmCreditorAccountsWithSamInformation(environmentService.realm, this.samLocale);
        this.creditorAccounts = data;
        this.openedByDefault = this.getCreditorAccountsIdsUpToDepthLevel(data, 3);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    async getChildren () {
      const i = 0;
      this.creditorAccounts.forEach(creditorAccount => {
        this.creditorAccountsWithChildren[i] = creditorAccount;
        this.listChildren = creditorAccount.children();
        this.listChildren.forEach(children => {
          this.creditorAccountsWithChildren.add(children);
        });
      });
      return this.creditorAccountsWithChildren;
    },
    openCreditorAccountUpdateDialog (name, id) {
      this.accountNameForDialog = name;
      this.accountIdForDialog = id;
      this.$refs.creditorAccountUpdateDialog.open();
    },
    openCreditorAccountDetailsDialog (creditorAccount) {
      this.creditorAccountForDialog = creditorAccount;
      this.$refs.creditorAccountDetailsDialog.open();
    },
    getCreditorAccountsIdsUpToDepthLevel (creditorAccounts, depth) {
      let accountIdList = [];
      let accountsList = creditorAccounts;
      for (let index = 1; index < depth; index++) {
        accountIdList = accountIdList.concat(accountsList.map(account => account.accountId));
        accountsList = accountsList.filter(account => account.children !== undefined)
          .flatMap(account => account.children);
      }
      return accountIdList;
    }
  },
  async beforeMount () {
    await this.loadDataTableContent();
  }
};
</script>