<template>
  <v-container>
    <v-card>
      <v-tabs>
        <v-tab :to="{ name: 'CreditorSettingsProfile' }" exact>{{ $t('SETTINGS_MY_PROFILE') }}</v-tab>
      </v-tabs>
      <router-view/>
    </v-card>
  </v-container>
</template>

<script>
export default {
};
</script>
