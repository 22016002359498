<template>
  <v-dialog v-model="manageRightsDialog" max-width="800px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
              v-on="{ ...tooltip, ...dialog}"
              v-bind="attrs"
              class="ma-2"
              color="secondary"
              small
              dark
          >
            <v-icon
                dark
                small
            >
              mdi-account-key-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('LABEL_MANAGE_RIGHTS' ) }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="pa-4 text-h6 force-break-word text-center">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="global-card-text">
        <v-row class="global-card-text">
          <v-col cols="12" sm="5" md="5">
            <v-card elevation="0">
              <v-card-title>{{ $t('LABEL_SUB_ROLES') }}</v-card-title>
              <v-card-text>
                <v-checkbox
                    v-for="item in creditorSubRoles"
                    :key="item.value"
                    v-model="selectedSubRoles"
                    :value="item.value"
                    multiple
                    hide-details
                    class="ma-0"
                >
                  <template v-slot:label>
                <span class="small">
                  {{ computeCreditorSubRole(item.value) }}
                </span>
                  </template>
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <v-divider :vertical="!this.$vuetify.breakpoint.xs" class="mx-3"></v-divider>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-card elevation="0">
              <v-card-title>{{ $t('LABEL_CREDITOR_ACCOUNTS') }}</v-card-title>
              <v-card-text class="creditor-accounts-card-text">
                <!-- We set the prop selection-type to null because the two values (leaf or independent)
                 proposed by vuetify don't meet the functional requirements of this component. -->
                <v-treeview
                    v-model="selectedCreditorAccounts"
                    selectable
                    :items="creditorAccounts"
                    dense
                    :selection-type="null"
                    :selected-color="this.$vuetify.theme.themes.light.primary"
                    class="treeview-custom"
                    open-all
                ></v-treeview>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn
            color="error"
            dark
            @click="manageRightsDialog = false"
        >
          {{ $t('ACTION_CANCEL') }}
        </v-btn>
        <v-btn
            color="primary"
            :disabled="submitButtonDisabled"
            @click="updateUserSubRoles"
        >
          {{ $t('ACTION_VALIDATE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userMixin from '@/mixins/userMixin';
import userService from '@/services/userService';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';

export default {
  mixins: [userMixin],
  props: {
    userProp: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      manageRightsDialog: false,
      valid: undefined,
      creditorUser: {},
      selectedSubRoles: [],
      selectedCreditorAccounts: [],
      submitButtonDisabled: null
    };
  },
  computed: {
    ...mapState(['creditorAccounts']),
    dialogTitle () {
      return `${this.$t('TITLE_MANAGE_RIGHTS_DIALOG')} ${this.creditorUser.email}`;
    }
  },
  watch: {
    userProp: {
      deep: true,
      immediate: true,
      async handler () {
        this.creditorUser = cloneDeep(this.userProp);
      }
    },
    selectedCreditorAccounts () {
      this.shouldDisableSubmitButton();
    },
    selectedSubRoles () {
      this.shouldDisableSubmitButton();
    }
  },
  methods: {
    closeDialog () {
      this.manageRightsDialog = false;
    },
    async updateUserSubRoles () {
      try {
        await userService.assignSubRolesToAccountManagerUser(this.selectedSubRoles,
          this.selectedCreditorAccounts, this.userProp.id, 'CREDITOR');

        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_UPDATE_SUB_ROLES'));
        this.closeDialog();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.$emit('refresh-table');
      }
    },
    shouldDisableSubmitButton () {
      if (this.selectedCreditorAccounts === undefined || this.selectedSubRoles === undefined) {
        this.submitButtonDisabled = true;
      } else {
        this.submitButtonDisabled = this.selectedCreditorAccounts?.length === 0 || this.selectedSubRoles?.length === 0;
      }
    }
  },
  beforeMount () {
    const subRoles = { ...this.$props.userProp.subRoles };
    this.selectedCreditorAccounts = subRoles.businessIds;
    this.selectedSubRoles = subRoles.roles;
    this.shouldDisableSubmitButton();
  }
};
</script>

<style scoped>
  .treeview-custom {
    max-height: 200px !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .global-card-text {
    min-height: 310px !important;
  }
  .creditor-accounts-card-text {
    padding: 0 !important;
  }
</style>