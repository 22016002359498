<template>
 <v-dialog v-model="validationDialog"
            width="550"
            persistent>
    <v-card>
      <v-card-title>
        {{ $t('ACCOUNT_UPDATE')}}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <div class="d-flex justify-center black--text">
            <div align="center" class="my-4">
              {{ $t('NEW_NAME') }}
              <v-text-field
              :label="nameAccount"
              v-model="newAccountName"
              ></v-text-field>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="space-around" class="my-4">
          <v-btn
            color="error"
            @click="close"
          >
            {{ $t('ACTION_CANCEL') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
          >
            {{ $t('ACTION_VALIDATE') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from '@/services/userService';
export default {
  name: 'CreditorAccountUpdateDialog',
  props: {
    nameAccount: {
      type: String
    },
    idAccount: {
      type: String
    }
  },
  computed: {
    nameAccountInput: {
      get: function () {
        return this.nameAccount;
      },
      set: function (newValue) {
        this.$emit('update:nameAccount', newValue);
      }
    },
    idAccountInput: {
      get: function () {
        return this.idAccount;
      },
      set: function (newValue) {
        this.$emit('update:idAccount', newValue);
      }
    }
  },
  data () {
    return {
      validationDialog: false,
      newAccountName: ''
    };
  },
  async beforeMount () {
    this.loading = true;
    this.newAccountName = '';
  },
  methods: {
    async save () {
      try {
        await userService.updateAccount(this.idAccount, this.newAccountName);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
      this.validationDialog = false;
      this.$emit('refresh-table');
    },
    close () {
      this.validationDialog = false;
    },
    open () {
      this.newAccountName = '';
      this.validationDialog = true;
    }
  }
};
</script>
