<script>
import { Pie } from 'vue-chartjs';
import 'chartjs-plugin-labels';
import numberMixin from '@/mixins/numberMixin';

export default {
  extends: Pie,
  props: ['kpiData', 'currencySymbol'],
  mixins: [numberMixin],
  data: () => ({
    firstSliceLimit: 30,
    secondSliceLimit: 60,
    thirdSliceLimit: 90
  }),
  mounted () {
    this.renderChart({
      labels: [
        this.$t('KPI_TO_PAY'),
        this.$t('LATE_FIRST_SLICE', [0, this.firstSliceLimit]),
        this.$t('LATE_SECOND_SLICE', [this.firstSliceLimit, this.secondSliceLimit]),
        this.$t('LATE_THIRD_SLICE', [this.secondSliceLimit, this.thirdSliceLimit]),
        this.$t('LATE_FOURTH_SLICE', [this.thirdSliceLimit])
      ],
      datasets: [
        {
          backgroundColor: ['#4CAF50', '#FF9800', '#E57373', '#B71C1C', '#8B1620'],
          data: [this.kpiData.current, this.kpiData.firstSlice, this.kpiData.secondSlice, this.kpiData.thirdSlice, this.kpiData.fourthSlice]
        }
      ]
    }, {
      legend: {
        position: 'bottom'
      },
      tooltips: {
        enabled: false
      },
      plugins: {
        labels: {
          render: (item) => item.value + ' ' + this.currencySymbol,
          fontColor: 'white',
          precision: 2
        }
      }
    });
  }
};
</script>