<template>
  <v-tabs
    background-color="primary accent-4"
    fixed-tabs
    dark>
    <v-tabs-slider class="d-none"></v-tabs-slider>
    <v-tab
      :class="isOnMobileSupport ? 'd-none' : ''"
      v-for="(item, i) in getSubMenus()"
      :key="i"
      :to="{ name: item.linkTo }"
      exact
    >
      {{ item.menuLabel }}
    </v-tab>
  </v-tabs>
</template>

<script>
import menuMixin from '@/mixins/menuMixin';
import userMixin from '@/mixins/userMixin';

export default {
  name: 'AppCreditorMenu',
  mixins: [menuMixin, userMixin],
  methods: {
    getSubMenus () {
      return this.menuItems.filter(menu => menu.subMenu?.some(subMenu => subMenu.linkTo === this.$route.name))[0].subMenu;
    }
  }
};
</script>