<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <v-col cols="12" md="8">
        <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="$t('SEARCH')"
            single-line
            hide-details
            clearable
        ></v-text-field>
      </v-col>
      <v-btn
        dark
        color="primary"
        @click="openAddOrEditUserDialog"
      >
        <v-icon class="mr-2">
          mdi-plus
        </v-icon>
        {{ $t('BUTTON_CREATE_USER') }}
      </v-btn>

    </v-card-title>
    <v-card-title class="col-sm-6 offset-sm-6">
    </v-card-title>
    <v-data-table
      :headers="tableHeaders"
      :items="creditorAccounts"
      :search="search"
      :loading="loading"
    >
      <template v-slot:item.mainRole="{ value }">
        {{ computeCreditorUserRole(value) }}
      </template>
      <template v-slot:item.enabled="{ item }">
        {{ item.enabled ? $t('ENABLED') : $t('DISABLED') }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="!loading && !isCurrentLoggedUser(item)">
          <CreditorSettingsUsersAddOrEditUser
            action="edit"
            @refresh-table="loadDataTableContent"
            :creditorAccountsGroups="creditorAccountsGroups"
            :userProp="item"
          />
          <CreditorSettingsManageRights
            v-if="isAccountManager(item)"
            @refresh-table="loadDataTableContent"
            :userProp="item"
          />
          <CreditorSettingsUsersDisableUser
            @refresh-table="loadDataTableContent"
            :userProp="item"
          />
        </div>
      </template>
    </v-data-table>
    <CreditorSettingsUsersAddOrEditUser
      action="add"
      @refresh-table="loadDataTableContent"
      :creditorAccountsGroups="creditorAccountsGroups"
      ref="addOrEditUser"
    />
  </v-card>
</template>

<script>
import userMixin from '@/mixins/userMixin';
import environmentService from '@/services/environmentService';
import userService from '@/services/userService';
import CreditorSettingsUsersAddOrEditUser from '@/views/creditor/settings/CreditorSettingsUsersAddOrEditUser.vue';
import CreditorSettingsUsersDisableUser from '@/views/creditor/settings/CreditorSettingsUsersDisableUser.vue';
import CreditorSettingsManageRights from '@/views/creditor/settings/CreditorSettingsManageRights';
import { mapState } from 'vuex';

export default {
  mixins: [userMixin],
  components: {
    CreditorSettingsUsersAddOrEditUser,
    CreditorSettingsUsersDisableUser,
    CreditorSettingsManageRights
  },
  data () {
    return {
      loading: true,
      creditorAccountsGroups: [],
      tableHeaders: [
        {
          text: this.$t('LABEL_EMAIL'),
          value: 'email'
        },
        {
          text: this.$t('LABEL_FIRSTNAME'),
          value: 'firstName'
        },
        {
          text: this.$t('LABEL_LASTNAME'),
          value: 'lastName'
        },
        {
          text: this.$t('LABEL_ROLE'),
          value: 'mainRole'
        },
        {
          text: this.$t('LABEL_ACCOUNT_STATUS'),
          value: 'enabled'
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      search: '',
      creditorAccounts: []
    };
  },
  computed: {
    ...mapState(['selectedCreditorAccount'])
  },
  methods: {
    async loadDataTableContent () {
      this.loading = true;
      try {
        const { data } = await userService.getCreditorAccountUsers(environmentService.realm);
        this.creditorAccounts = data.content;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    openAddOrEditUserDialog () {
      this.$refs.addOrEditUser.openDialog();
    },
    isCurrentLoggedUser (user) {
      return this.user.id === user.id;
    },
    isAccountManager (user) {
      return user.mainRole === 'CREDITOR_ACCOUNT_MANAGER';
    }
  },
  async beforeMount () {
    await this.loadDataTableContent();
    this.loading = true;
    try {
      const { data } = await userService.getCreditorAccountsGroup(this.user.id);
      this.creditorAccountsGroups = data;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  },
  watch: {
    async selectedCreditorAccount () {
      await this.loadDataTableContent();
    }
  }
};
</script>
