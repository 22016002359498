<template>
  <v-card>
    <v-card-title>
      <ExportCsvButton
          :loadingData="loading"
          :headers="exportableHeaders"
          :invoices="invoices"
          :filename="$t('NAME_CSV_FILE_CREATED')">
      </ExportCsvButton>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('SEARCH')"
          single-line
          hide-details
          clearable
          @input="getInvoices(debtorAccounts.map(debtorAccount => debtorAccount.id))"
      ></v-text-field>
    </v-card-title>
    <v-row>
      <DebtorAccountSelectorForCreditor
        :class="{'debtor-selector': true, 'debtor-selector-not-xs': !$vuetify.breakpoint.xs}"
        :initialSelectedDebtorAccounts="debtorAccounts"
      />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers.filter(header => !header.hidden)"
            :items="invoices"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalInvoices"
            :items-per-page="20"
            :footer-props="footerProps"
            :sort-by.sync="defaultSortBy">

          <template v-slot:item.debtorName="{ item }">
            <strong>{{ item.debtorName }}</strong>
          </template>
          <template v-slot:item.refDoc="{ item }">
            <InvoiceNumberWithDownloadLink :invoice="item"></InvoiceNumberWithDownloadLink>
          </template>
          <template v-slot:item.issueDate="{ item }">
            <span>{{ item.issueDate | date }}</span>
          </template>
          <template v-slot:item.amountExclusiveOfTax="{ item }">
            <span>{{ item.amountExclusiveOfTax | currency(item.currency) }}</span>
          </template>
          <template v-slot:item.amountWithTax="{ item }">
            <span>{{ item.amountWithTax | currency(item.currency) }}</span>
          </template>
          <template v-slot:item.dueDate="{ item }">
            <span>{{ item.dueDate | date }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <span>
              {{ $t(invoiceStatus(item), { paiementDate: $options.filters.date(item.paymentDate) }) }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" :to="{
              name: 'CreditorInvoiceDetail',
              params: { id: item.id } }">
              {{ $t('BUTTON_DETAILS') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import ExportCsvButton from '@/components/common/buttons/ExportCsvButton';
import InvoiceNumberWithDownloadLink from '@/components/common/buttons/InvoiceNumberWithDownloadLink';
import DebtorAccountSelectorForCreditor from '@/components/creditor/DebtorAccountSelectorForCreditor';

import { mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'InvoicePaidTableForCreditor',
  mixins: [dateMixin, numberMixin, userMixin],
  components: {
    ExportCsvButton,
    InvoiceNumberWithDownloadLink,
    DebtorAccountSelectorForCreditor
  },
  props: {
    debtorAccounts: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      totalInvoices: 0,
      options: { },
      invoices: [],
      search: '',
      loading: true,
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100]
      },
      defaultSortBy: 'issueDate',
      headers: [
        {
          text: this.$t('DEBTOR_ACCOUNT'),
          value: 'debtorName',
          exportable: true
        },
        {
          text: this.$t('ACCOUNT_ID'),
          value: 'billingAccount',
          exportable: true,
          hidden: true
        },
        {
          text: this.$t('LABEL_DOCUMENT_REFERENCE'),
          value: 'refDoc',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('STATUS'),
          value: 'status',
          exportable: true,
          hidden: true
        },
        {
          text: this.$t('INVOICE_ISSUE_DATE'),
          value: 'issueDate',
          sortable: false,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_EXCLUDING_TAXES'),
          value: 'amountExclusiveOfTax',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_INCLUDING_TAXES'),
          value: 'amountWithTax',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('INVOICE_DUE_DATE'),
          value: 'dueDate',
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('STATUS'),
          value: 'status',
          sortable: true,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          value: 'actions',
          sortable: false,
          exportable: false
        }
      ]
    };
  },
  computed: {
    exportableHeaders () {
      return this.headers.filter(header => !!header.exportable);
    },
    ...mapState(['selectedCreditorAccount'])
  },
  methods: {
    getInvoices: debounce(async function (debtorIds) {
      if (!this.search) {
        this.search = '';
      }
      this.loading = true;
      try {
        const { data } = await invoiceService.getPaginatedNotPaidYetInvoicesByDebtorIds(debtorIds, this.selectedCreditorAccount.id,
          this.search, this.options);
        this.totalInvoices = data.totalElements;
        this.invoices = invoiceMapper.mapAll(data.content);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }, 500),
    invoiceStatus (invoice) {
      let invoiceStatus = invoice.status;
      if (invoice.status === 'TO_VALIDATE' || invoice.status === 'TO_PAY') {
        invoiceStatus = 'TO_PAY';
      } else if (invoice.status === 'PAID' || invoice.status === 'SETTLED') {
        invoice.status = 'PAID_ON_DATE';
      }
      return invoiceStatus;
    }
  },
  watch: {
    options: {
      handler () {
        return this.getInvoices(this.debtorAccounts.map(debtorAccount => debtorAccount.id));
      },
      deep: true
    },
    debtorAccounts () {
      this.search = '';
      if (this.debtorAccounts.length !== 0) {
        return this.getInvoices(this.debtorAccounts.map(debtorAccount => debtorAccount.id));
      } else {
        this.invoices = [];
      }
    }
  }
};
</script>

<style scoped>
  .debtor-selector {
    padding-right: 15px;
    padding-left: 15px;
  }

  .debtor-selector-not-xs {
    max-width: 370px;
    min-width: 200px;
    padding-left: 40px;
  }
</style>