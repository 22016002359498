<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <InvoiceToPayTableForCreditor
          v-if="!loading"
          :debtorAccounts="selectedDebtorAccountsForCreditor"
        />
        <div v-else class="app-loading text-center">
          <v-progress-circular
              :size="70"
              indeterminate
              color="primary">
          </v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import InvoiceToPayTableForCreditor from '@/components/creditor/InvoiceToPayTableForCreditor';
import creditorTablesMixin from '@/mixins/creditorTablesMixin';

export default {
  name: 'CreditorToPayInvoices',
  components: { InvoiceToPayTableForCreditor },
  mixins: [creditorTablesMixin],
  computed: {
    ...mapState(['selectedDebtorAccountsForCreditor'])
  }
};
</script>
