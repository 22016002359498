import { mapState } from 'vuex';

export default {
  data () {
    return {
      loading: false
    };
  },
  methods: {
    async getDebtorsFromCreditorId (creditorId) {
      try {
        const debtorsList = [];
        await this.$store.dispatch('setSelectedDebtorAccountsForCreditor', { debtorAccounts: debtorsList.data });
      } catch (err) {
        this.displayErrorSnackbar(this.$t('ERROR_FETCHING_DEBTORS'));
      }
    }
  },
  computed: {
    ...mapState(['selectedCreditorAccount'])
  },
  watch: {
    async selectedCreditorAccount () {
      this.loading = true;
      await this.getDebtorsFromCreditorId(this.selectedCreditorAccount.id);
      this.loading = false;
    }
  }
};
