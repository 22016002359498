<template>
 <span>{{ text }} </span>
</template>

<script>

export default {
  name: 'CreditorPaymentInvoices',
  data: () => ({
    text: 'Creditor Payment Invoices'
  })
};
</script>