<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-form
              ref="form"
              v-model="valid"
          >
            <v-card-title>
              {{ $t('DEBT_CREATION') }}
            </v-card-title>
            <v-row class="mx-2">
              <v-col cols="12" lg="6">
                <span class="black--text font-weight-bold">
                  {{ $t('INVOICE_INFORMATIONS') }}
                </span>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        :label="$t('CREDITOR_ACCOUNT')"
                        disabled
                        :rules="[rules.requiredFieldRules]"
                        v-model="currentCreditor"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        :label="$t('ONE_OFF_REFERENCE')"
                        v-model="invoiceData.ref"
                        :disabled="loading"
                        :rules="[rules.requiredFieldRules]"
                        counter="15"
                        maxlength="15"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-currency-field
                        :label="$t('AMOUNT_EXCLUSIVE_OF_TAX', { currency: creditorCurrency })"
                        v-model="invoiceData.amountExclusiveOfTax"
                        :prefix="creditorCurrency"
                        :disabled="loading"
                        :rules="[rules.requiredFieldRules, rules.positiveAmountValue]"
                        required
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-currency-field
                        :label="$t('AMOUNT_WITH_TAX', { currency: creditorCurrency })"
                        v-model="invoiceData.amountWithTax"
                        :prefix="creditorCurrency"
                        :disabled="loading"
                        :rules="[rules.requiredFieldRules, rules.positiveAmountValue]"
                        required
                    />
                  </v-col>
                  <v-col cols="12">
                    <date-picker
                        ref="paiementDateLimit"
                        :label="$t('PAYMENT_DEADLINE')"
                        v-model="invoiceData.paiementDateLimit"
                        :disabled="loading"
                        :rules="[rules.requiredFieldRules]"
                        required/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6">
                <span class="black--text font-weight-bold">
                  {{ $t('DEBTOR_CONTACT') }}
                </span>
                <v-row>
                  <v-col cols="2">
                    <v-select
                        :label="$t('LABEL_TITLE')"
                        v-model="invoiceData.userTitle"
                        :items="titleItems"
                        :rules="[rules.requiredFieldRules]"
                        :disabled="loading"
                        required
                    ></v-select>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        :label="$t('LABEL_FIRSTNAME')"
                        v-model="invoiceData.userFirstName"
                        :disabled="loading"
                        :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
                        counter="20"
                        max-length="20"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        :label="$t('LABEL_LASTNAME')"
                        v-model="invoiceData.userLastName"
                        :disabled="loading"
                        :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
                        counter="20"
                        max-length="20"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-4" cols="12">
                    <v-radio-group
                      v-model="transmissionMean"
                      row
                      mandatory
                      :disabled="loading"
                    >
                      <v-radio
                        label="SMS"
                        value="phone"
                      ></v-radio>
                      <v-radio
                        label="E-mail"
                        value="email"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" v-if="transmissionMean === 'phone'">
                    <PhoneNumberInput
                      class="pt-3"
                      :phone-number.sync="phoneNumber"
                      :key="index"
                      :disabled="loading"
                      required
                      :phone-mask-key.sync="phoneMaskKey"
                      :creditorId="selectedCreditorAccount.id">
                    </PhoneNumberInput>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-text-field
                        label="Email"
                        v-model="invoiceData.userEmail"
                        :rules="[rules.emailRules, rules.requiredFieldRules]"
                        :disabled="loading"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="resetForm"
                  depressed
                  color="primary"
                  class="mr-3"
                  :disabled="loading"
              >{{ $t('RESET_FORM') }}</v-btn>
              <v-btn
                  @click="createInvoice"
                  depressed
                  color="primary"
                  class="mr-3"
                  :loading="loading"
                  :disabled="!valid"
              >{{ $t('GENERATE_LINK') }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-alert
        v-if="showSuccessAlert"
        class="my-2"
        type="success">
      {{ $t('ONE_OFF_CREATED_SUCCESSFULLY', { debtorName }) }} <router-link :to="{ name: 'CreditorInvoiceDetail', params: { id: this.createdOneOffInvoiceId } }">{{ $t('CLICK_FOR_DETAILS') }}</router-link>
    </v-alert>
    <v-alert
        v-if="showErrorAlert"
        class="my-2"
        type="error">
      {{ errorAlertMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import creditorTablesMixin from '@/mixins/creditorTablesMixin';
import moment from 'moment-timezone';
import DatePicker from '@/components/common/DatePicker';
import userMixin from '@/mixins/userMixin';
import ruleMixin from '@/mixins/ruleMixin';
import { mapState } from 'vuex';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';
import maskMixin from '@/mixins/maskMixin';
import { ErrorMessagesCorrelationMap } from '@/enums/errorMessageCorrelationMap';

export default {
  name: 'CreditorCreateInvoices',
  mixins: [creditorTablesMixin, userMixin, ruleMixin, maskMixin],
  components: {
    DatePicker,
    PhoneNumberInput
  },
  data () {
    return {
      valid: undefined,
      transmissionMean: 'phone',
      loading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertMessage: '',
      phoneNumber: '',
      phoneMaskKey: '',
      index: 0,
      invoiceData: {},
      createdOneOffInvoiceData: {},
      createdOneOffInvoiceId: ''
    };
  },
  created () {
    this.resetForm();
  },
  mounted () {
    this.$refs.form?.resetValidation();
  },
  methods: {
    async createInvoice () {
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      this.loading = true;
      this.invoiceData.userPhoneNumber = this.replaceWithoutBlank(this.phoneNumber);
      this.createdOneOffInvoiceData = {
        debt: {
          reference: this.invoiceData.ref,
          dueDate: this.invoiceData.paiementDateLimit,
          taxExclusiveAmount: this.invoiceData.amountExclusiveOfTax,
          taxInclusiveAmount: this.invoiceData.amountWithTax
        },
        contact: {
          title: this.invoiceData.userTitle,
          lastName: this.invoiceData.userLastName,
          firstName: this.invoiceData.userFirstName,
          phone: this.transmissionMean === 'phone' ? this.phoneNumber : null,
          emailAddress: this.transmissionMean === 'email' ? this.invoiceData.userEmail : null
        }
      };
      try {
        const response = await invoiceService.createOneOffInvoice(this.createdOneOffInvoiceData,
          this.selectedCreditorAccount.id);
        this.createdOneOffInvoiceId = response.data.debt.invoiceId;
        this.showSuccessAlert = true;
        this.resetForm();
      } catch (err) {
        this.errorAlertMessage = this.getErrorMessage(err.response.data.errors[0]);
        this.showErrorAlert = true;
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    resetForm () {
      this.invoiceData = {
        paiementDateLimit: moment().add(1, 'months').toISOString(),
        amountExclusiveOfTax: 0,
        amountWithTax: 0
      };
      this.creditorCurrency = this.selectedCreditorAccount.creditorSettings.CURRENCY.value;
      this.transmissionMean = 'phone';
      this.phoneMaskKey = '';
      this.phoneNumber = '';
      this.index++;
      this.$refs.form?.resetValidation();
    },
    getErrorMessage (errorReturned) {
      return ErrorMessagesCorrelationMap.has(errorReturned) ? this.messageToLocal(ErrorMessagesCorrelationMap.get(errorReturned)) : this.$t('ONE_OFF_CREATION_FAILED_ALERT_ERROR');
    }
  },
  computed: {
    ...mapState([
      'selectedCreditorAccount'
    ]),
    currentCreditor () {
      return this.selectedCreditorAccount.name + ' - ' + this.selectedCreditorAccount.accountId;
    },
    debtorName () {
      return [this.titleItems.filter(title => title.value === this.createdOneOffInvoiceData.contact.title)[0].text,
        this.createdOneOffInvoiceData.contact.lastName,
        this.createdOneOffInvoiceData.contact.firstName].join(' ');
    }
  }
};
</script>
