<template>
 <span>{{ text }} </span>
</template>

<script>

export default {
  name: 'CreditorDataTransfer',
  data: () => ({
    text: 'Creditor Data Transfer'
  })
};
</script>