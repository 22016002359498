var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('ExportCsvButton',{attrs:{"loadingData":_vm.loading,"headers":_vm.exportableHeaders,"invoices":_vm.invoices,"filename":_vm.$t('NAME_CSV_FILE_CREATED')}}),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":"","clearable":""},on:{"input":function($event){_vm.getInvoices(_vm.debtorAccounts.map(function (debtorAccount) { return debtorAccount.id; }))}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('DebtorAccountSelectorForCreditor',{class:{'debtor-selector': true, 'debtor-selector-not-xs': !_vm.$vuetify.breakpoint.xs},attrs:{"initialSelectedDebtorAccounts":_vm.debtorAccounts}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers.filter(function (header) { return !header.hidden; }),"items":_vm.invoices,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalInvoices,"items-per-page":20,"footer-props":_vm.footerProps,"sort-by":_vm.defaultSortBy},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.defaultSortBy=$event},"update:sort-by":function($event){_vm.defaultSortBy=$event}},scopedSlots:_vm._u([{key:"item.debtorName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.debtorName))])]}},{key:"item.refDoc",fn:function(ref){
var item = ref.item;
return [_c('InvoiceNumberWithDownloadLink',{attrs:{"invoice":item}})]}},{key:"item.issueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.issueDate)))])]}},{key:"item.amountExclusiveOfTax",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amountExclusiveOfTax,item.currency)))])]}},{key:"item.amountWithTax",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amountWithTax,item.currency)))])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.dueDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.invoiceStatus(item), { paiementDate: _vm.$options.filters.date(item.paymentDate) }))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","to":{
            name: 'CreditorInvoiceDetail',
            params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON_DETAILS'))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }