<template>
  <v-menu
      v-model="menu"
      :top="top"
      :close-on-content-click="false"
      offset-y
      max-width="290px"
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          ref="dateTextFieldRef"
          v-model="dateFormatted"
          :rules="datePickerRules"
          :label="label"
          @blur="content = parseDate(dateFormatted)"
          v-on="on"
          :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker v-model="content" no-title @input="menu = false" @blur="menu = false"></v-date-picker>
  </v-menu>
</template>

<script>

// utilities
import moment from 'moment-timezone';
import ruleMixin from '@/mixins/ruleMixin';

export default {
  mixins: [ruleMixin],
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    customRules: {
      type: Array,
      default: () => []
    },
    dependencies: {
      type: Array,
      default: () => []
    }
  },
  async created () {
    // On place la règle de format en premier de manière à ce que ce soit le premier contrôle réalisé
    this.datePickerRules.unshift(this.rules.dateRules);
  },
  data () {
    const formattedValue = !this.value ? null : moment(this.value).format('YYYY-MM-DD');
    return {
      menu: false,
      content: formattedValue,
      dateFormatted: this.formatDate(formattedValue),
      datePickerRules: this.customRules
    };
  },
  watch: {
    // Met à jour la valeur du parent lorsque le contenu est modifié
    content () {
      this.dateFormatted = this.formatDate(this.content);
      this.$emit('input', !this.content ? null : moment(this.content).format('YYYY-MM-DD[T]HH:mm:ss'));
      this.dependencies.forEach(dependency => {
        !!dependency.validate && dependency.validate();
      });
    },
    // Permet de mettre à jour la date si le parent modifie la modifie
    value () {
      const formattedValue = !this.value ? null : moment(this.value).format('YYYY-MM-DD');
      if (formattedValue !== this.content) {
        this.content = formattedValue;
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        if (!this.content) {
          return null;
        }
        return this.formatDate(this.content);
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate (date) {
      if (!date) {
        if (!this.dateFormatted) {
          return null;
        }
        return this.parseDate(this.dateFormatted);
      }
      // Si la date est invalide, on ne la traite pas et on supprime la date affichée
      if (!this.validateDate(date)) {
        this.dateFormatted = null;
        return null;
      }
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    validate () {
      return this.$refs.dateTextFieldRef.validate();
    }
  }
};
</script>
